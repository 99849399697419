import { SessionStorage } from 'quasar';
import { auth0 } from 'boot/auth';

class TokenProvider {
  token: string | undefined;
  tokenSrc: string | undefined;

  constructor(token?: string) {
    this.token = token;
  }

  getTokenSrc() {
    if (this.tokenSrc) {
      return this.tokenSrc;
    }
    const { isAuthenticated } = auth0;

    if (isAuthenticated.value) {
      return 'ext';
    }
    return 'int';
  }

  async getToken() {
    if (this.token) {
      return this.token;
    }
    const { isAuthenticated, getAccessTokenSilently } = auth0;

    if (isAuthenticated.value) {
      return getAccessTokenSilently();
    }
    return Promise.resolve(SessionStorage.getItem('user')?.token);
  }
}

export class Service {
  baseUri = process.env.API_URL;
  tokenProvider;

  constructor(token?: string) {
    this.tokenProvider = new TokenProvider(token);
  }

  async get(path: string) {
    return fetch(`${this.baseUri}${path}`, {
      headers: {
        Authorization: `Bearer ${await this.tokenProvider.getToken()}`,
        'X-Token-Src': this.tokenProvider.getTokenSrc(),
        'X-User-Id': SessionStorage.getItem('user')?.id,
      },
    }).then((response) =>
      response.ok ? Promise.resolve(response.json()) : Promise.reject(response),
    );
  }

  async getRaw(path: string) {
    return fetch(`${this.baseUri}${path}`, {
      headers: {
        Authorization: `Bearer ${await this.tokenProvider.getToken()}`,
        'X-Token-Src': this.tokenProvider.getTokenSrc(),
        'X-User-Id': SessionStorage.getItem('user')?.id,
      },
    }).then((response) =>
      response.ok ? Promise.resolve(response.text()) : Promise.reject(response),
    );
  }

  async getBytes(path: string) {
    return fetch(`${this.baseUri}${path}`, {
      headers: {
        Authorization: `Bearer ${await this.tokenProvider.getToken()}`,
        'X-Token-Src': this.tokenProvider.getTokenSrc(),
        'X-User-Id': SessionStorage.getItem('user')?.id,
      },
    }).then((response) =>
      response.ok ? Promise.resolve(response.blob()) : Promise.reject(response),
    );
  }

  async delete(path: string) {
    return fetch(`${this.baseUri}${path}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${await this.tokenProvider.getToken()}`,
        'X-Token-Src': this.tokenProvider.getTokenSrc(),
        'X-User-Id': SessionStorage.getItem('user')?.id,
      },
    }).then((response) => (response.ok ? Promise.resolve() : Promise.reject()));
  }

  async post(path: string, data: object, content_type = 'application/json') {
    return fetch(`${this.baseUri}${path}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${await this.tokenProvider.getToken()}`,
        'Content-Type': content_type,
        'X-Token-Src': this.tokenProvider.getTokenSrc(),
        'X-User-Id': SessionStorage.getItem('user')?.id,
      },
      body: JSON.stringify(data),
    }).then((response) =>
      response.ok ? Promise.resolve(response.json()) : Promise.reject(response),
    );
  }

  async put(path: string, data: object, content_type = 'application/json') {
    return fetch(`${this.baseUri}${path}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${await this.tokenProvider.getToken()}`,
        'Content-Type': content_type,
        'X-Token-Src': this.tokenProvider.getTokenSrc(),
        'X-User-Id': SessionStorage.getItem('user')?.id,
      },
      body: JSON.stringify(data),
    }).then((response) =>
      response.ok ? Promise.resolve(response.json()) : Promise.reject(),
    );
  }

  async patch(path: string, data: object, content_type = 'application/json') {
    return fetch(`${this.baseUri}${path}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${await this.tokenProvider.getToken()}`,
        'Content-Type': content_type,
        'X-Token-Src': this.tokenProvider.getTokenSrc(),
        'X-User-Id': SessionStorage.getItem('user')?.id,
      },
      body: JSON.stringify(data),
    }).then((response) =>
      response.ok ? Promise.resolve(response.json()) : Promise.reject(),
    );
  }
}
