import { Notify, SessionStorage } from 'quasar';
import { route } from 'quasar/wrappers';
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router';
import routes from './routes';
import { DlaClient } from 'src/services/dla-api';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */
export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === 'history'
      ? createWebHistory
      : createWebHashHistory;

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(
      process.env.MODE === 'ssr' ? void 0 : process.env.VUE_ROUTER_BASE,
    ),
  });

  Router.beforeEach(async (to) => {
    if (to.query.key) {
      try {
        await new DlaClient(to.query.key)
          .get('/users/identify')
          .then((user) => {
            SessionStorage.set('user', user);
            Router.replace({ path: to.path, query: undefined });
          });
      } catch (e) {
        Notify.create({
          message:
            'There was a problem authenticating you. Please try again and, if the problem persists, contact us at support@dl-academy.com',
          position: 'center',
        });
      }
    } else if (to.query.code && to.query.email) {
      await new DlaClient()
        .post('/users/login', {
          email: to.query.email,
          code: to.query.code,
        })
        .then((user) => {
          SessionStorage.set('user', user);
          Router.replace({ path: to.path, query: undefined });
        })
        .catch(() => {
          Router.replace({ path: '/login', query: { expired: 1 } });
        });
    }
  });

  return Router;
});
