import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/module/:id/class/:idx',
    component: () => import('layouts/VeryEmptyLayout.vue'),
    children: [
      { path: '', component: () => import('pages/learning/Class.vue') },
    ],
  },
  {
    path: '/module/:id/class/:idx/material/:idy',
    component: () => import('layouts/VeryEmptyLayout.vue'),
    children: [
      { path: '', component: () => import('pages/learning/Material.vue') },
    ],
  },
  {
    path: '/privacy',
    meta: {
      open: true,
    },
    component: () => import('layouts/EmptyLayout.vue'),
    children: [
      { path: '', component: () => import('pages/PrivacyPolicy.vue') },
    ],
  },
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('components/Interstitial.vue') },
      { path: '/lna', component: () => import('pages/lna/Index.vue') },
      {
        path: '/power-bi-instructions',
        component: () => import('pages/PowerBiInstructions.vue'),
      },
      {
        path: '/learning/dashboard',
        component: () => import('pages/learning/Dashboard.vue'),
      },
      {
        path: '/courses',
        component: () => import('pages/learning/Courses.vue'),
      },
      {
        path: '/learning/trophies',
        component: () => import('pages/learning/TrophyCabinet.vue'),
      },
      {
        path: '/dashboard',
        component: () => import('pages/DashboardPage.vue'),
      },
      { path: '/users', component: () => import('pages/Users.vue') },
      { path: '/users/:id', component: () => import('pages/User.vue') },
      {
        path: '/lna/pas',
        component: () => import('pages/lna/PersonaAssociation.vue'),
      },
      {
        path: '/lna/kns',
        component: () => import('pages/lna/KnowledgeAndSkills.vue'),
      },
      {
        path: '/assessments',
        children: [
          {
            path: ':id',
            component: () => import('pages/Assessment.vue'),
            name: 'assessment',
          },
        ],
      },
      {
        path: '/assessment-report/:id',
        component: () => import('pages/AssessmentReport.vue'),
      },
      {
        path: '/manage/assessments',
        component: () => import('components/admin/Assessments.vue'),
      },
      {
        path: '/manage/templates',
        component: () => import('pages/management/Templates.vue'),
      },
      {
        path: '/manage/cohorts',
        component: () => import('pages/management/Cohorts.vue'),
      },
      {
        path: '/manage/intakes',
        children: [
          {
            path: '',
            component: () => import('pages/management/intakes/Index.vue'),
            name: 'intakes',
          },
          {
            path: 'create',
            component: () => import('pages/management/intakes/Create.vue'),
            name: 'createIntake',
          },
          {
            path: ':id/import',
            component: () => import('pages/management/intakes/Import.vue'),
          },
        ],
      },
      {
        path: '/manage/learners',
        children: [
          {
            path: '',
            component: () => import('pages/management/learners/Index.vue'),
            name: 'learners',
          },
          {
            path: ':id',
            component: () => import('pages/management/learners/Record.vue'),
            name: 'editLearner',
          },
        ],
      },
      {
        path: '/manage/organisations',
        children: [
          {
            path: '',
            component: () => import('pages/management/organisations/Index.vue'),
            name: 'organisations',
          },
          {
            path: 'create',
            component: () =>
              import('pages/management/organisations/Create.vue'),
            name: 'createOrganisation',
          },
          {
            path: ':id',
            component: () =>
              import('pages/management/organisations/Record.vue'),
            name: 'editOrganisation',
          },
        ],
      },
      {
        path: '/manage/lessons',
        children: [
          {
            path: '',
            component: () => import('pages/management/lessons/Index.vue'),
            name: 'lessons',
          },
          {
            path: 'create',
            component: () => import('pages/management/lessons/Create.vue'),
            name: 'createLesson',
          },
          {
            path: ':id',
            component: () => import('pages/management/lessons/Record.vue'),
            name: 'editLesson',
          },
        ],
      },
      {
        path: '/manage/users',
        component: () => import('pages/management/Users.vue'),
      },
      {
        path: '/manage/certifications',
        component: () => import('pages/management/Certifications.vue'),
      },
      {
        path: '/manage/classes',
        component: () => import('pages/management/Classes.vue'),
      },
      { path: '/module/:id', component: () => import('pages/Module.vue') },
      {
        path: '/manage/modules',
        component: () => import('pages/management/Modules.vue'),
      },
      {
        path: '/manage/self-serve-modules',
        children: [
          {
            path: '',
            component: () =>
              import('pages/management/self-serve-modules/Index.vue'),
            name: 'ssModules',
          },
          {
            path: 'create',
            component: () =>
              import('pages/management/self-serve-modules/Create.vue'),
            name: 'createSSModule',
          },
          {
            path: ':id',
            component: () =>
              import('pages/management/self-serve-modules/Record.vue'),
            name: 'editSSModule',
          },
        ],
      },
      {
        path: '/manage/learning-objectives',
        children: [
          {
            path: '',
            component: () =>
              import('pages/management/learning-objectives/Index.vue'),
            name: 'learningObjectives',
          },
          {
            path: 'create',
            component: () =>
              import('pages/management/learning-objectives/Create.vue'),
            name: 'createLearningObjective',
          },
          {
            path: ':id',
            component: () =>
              import('pages/management/learning-objectives/Record.vue'),
            name: 'editLearningObjective',
          },
        ],
      },
      {
        path: 'manage/pages',
        children: [
          {
            path: '',
            component: () =>
              import('pages/management/self-serve-pages/Index.vue'),
            name: 'pages',
          },
          {
            path: 'create',
            component: () =>
              import('pages/management/self-serve-pages/Create.vue'),
            name: 'createPage',
          },
          {
            path: ':id',
            component: () =>
              import('pages/management/self-serve-pages/Record.vue'),
            name: 'editPage',
          },
        ],
      },
      {
        path: 'manage/surveys',
        children: [
          {
            path: '',
            component: () => import('pages/management/surveys/Index.vue'),
            name: 'surveys',
          },
          {
            path: 'create',
            component: () => import('pages/management/surveys/Create.vue'),
            name: 'createSurvey',
          },
          {
            path: ':id',
            component: () => import('pages/management/surveys/Record.vue'),
            name: 'editSurvey',
          },
          {
            path: ':id/iframe',
            component: () => import('pages/management/surveys/Preview.vue'),
            name: 'iframeSurvey',
          },
        ],
      },
      {
        path: '/manage/lessons',
        component: () => import('pages/management/Lessons.vue'),
      },
      {
        path: '/manage/active-modules',
        component: () => import('pages/management/ActiveModules.vue'),
      },
      {
        path: '/manage/module/:id',
        component: () => import('pages/management/ModuleEdit.vue'),
      },
      {
        path: '/manage/comms-templates/:id?',
        component: () => import('pages/management/CommsTemplates.vue'),
      },
      {
        path: '/manage/comms',
        component: () => import('pages/management/Comms.vue'),
      },
      {
        path: '/manage/activities',
        component: () => import('pages/management/Activities.vue'),
      },
    ],
  },
  {
    path: '/login',
    component: () => import('layouts/EmptyLayout.vue'),
    children: [{ path: '', component: () => import('pages/Login.vue') }],
  },
  {
    path: '/manage/surveys/:id/preview',
    component: () => import('layouts/PreviewLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/management/surveys/PreviewWrapper.vue'),
        name: 'previewSurvey',
      },
    ],
  },
  {
    path: '/register',
    component: () => import('layouts/EmptyLayout.vue'),
    children: [{ path: '', component: () => import('pages/Register.vue') }],
    meta: {
      open: true,
    },
  },
  {
    path: '/auth',
    component: () => import('pages/AuthRedirect.vue'),
    meta: {
      open: true,
    },
  },
  {
    path: '/formkit-testing',
    component: () => import('pages/FormkitTesting.vue'),
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue'),
  },
];

export default routes;
